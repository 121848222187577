<!-- 解决方案公共组件 应用场景 带图的 -->
<template>
  <div class="apps-container">
    <div class="banxin">
      <Subhead :enVal="'Applications  '" :znVal="'应用场景'" />
      <div class="apps-of-img">
        <div class="apps-text">{{ appsData.text| myTrim }}</div>
        <img class="apps-img" :src="appsData.imgUrl" alt="">
      </div>
      <div class="know-more" v-show="IsKnowMore" @click="gotoAbout">了解更多</div>
    </div>
  </div>
</template>

<script>
import Subhead from "./Subheading.vue"
export default {
  props: {
    appsData: {
      type: Object,
      default: {
        text: "",
        imgUrl: ""
      }
    },
    IsKnowMore: { //控制了解更多的显示隐藏
      type: Boolean,
      default: true
    }
  },
  components: {
    Subhead
  }

}
</script>

<style lang="less" scoped>
.apps-container {
  padding: 74px 0 71px;

  // background-color: skyblue;
  .apps-of-img {
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
    // background-color: red;

    .apps-text {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      letter-spacing: 1px;
      // padding-top: 67px;
      text-align: justify;
    }

    .apps-img {
      display: block;
      min-width: 549px;
      min-height: 309px;
      margin-left: 85px;
    }
  }

  .know-more {
    width: 136px;
    height: 44px;
    background: #2A5CE9;
    text-align: center;
    line-height: 44px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    border-radius: 30px;
    cursor: pointer;
  }
}
</style>