<!-- 药企样品库 1-->
<template>
  <div class="medicineBank">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'药企样品管理系统'" />
    <Business :business="business" />
    <appsOfImg :appsData="appsData" :IsKnowMore="false" />
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import appsOfImg from "./components/appsOfImg.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    appsOfImg
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner11.png"),
      business: {
        text: "生物制药企业样本库信息管理系统要求结合相关法规、企业SOP，对样品（细胞、抗体等）的入库、质检、出库领用、销毁等流程进行计算机化管理，以实现样品全生命周期的数据追踪和审计要求。",
        Imgs: []
      },
      appsData: {
        text: "系统采用半定制开发方式，基于企业质量管理体系实施完成。系统根据企业SOP的要求，对涉及使用系统的工作人员（包括管理员、仓库管理员、生产人员、研发人员、QA人员、QC人员等）进行严格的权限分级管理，并遵从法规要求确保数据安全，存储的数据及转移过程具有不可更改性，且具有数据备份、存档和恢复功能以及审计追踪功能。系统根据生物药企生产和研发需求，通过不同的控制节点，合规管理生产和研发样品。",
        imgUrl: require("@/assets/images/cases/content/apps-medicineBank01.png")
      },
    }
  }
}
</script>

<style lang="less" scoped>
</style>